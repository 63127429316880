.contact-info {
  margin-bottom: 2vmin;
}

.contact-info > h6 {
  display: inline;
}

.contact-logos {
  margin-inline: 4px;
  height: 30px;
}

@media (max-width: 576px) {
  #contact-header {
    display: block;
  }
}