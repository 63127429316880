.about-text {
  display: flex;
  /* width: 100vw; */
  flex-direction: column;
  align-items: center;
  /* position: absolute;
  top: 150vh; */
  /* margin-bottom: 100vh */
}

#about-gif {
  border-radius: 2vmin;
  background-image: url('../../../public/images/gifs/ezgif.com-crop.gif');
}

#expand-about-btn {
  width: 300px;
  height: 77px;
  overflow: hidden;
  cursor: pointer;
  background-color: rgb(222, 222, 222);
  padding: none;
  border-radius: 2vmin;
  transition: background-color 2s, color 2s, text-shadow 2s, height 2s;
}

#expand-about-btn:hover {
  text-shadow: 1px 1px 2px black;
  background-color: rgba(222,222,222, 0);
  color: white;
}

#expand-about-btn > h2 {
  margin: 0;
  font-size: xx-large;
}

#about-expanded {
  /* background-color: red; */
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  height: 0;
  overflow: hidden;
  width: 75%;
  border: 0.5vmin solid black;
  /* margin-bottom: 100vh; */
  margin-top: 3vmin;
  transition: height 2s;
}

#about-expanded > h5 {
  grid-column-start: 3;
}

#about-expanded > button {
  color: red;
  grid-column-start: 5;
}

#about-p {
  text-align: left;
  margin: 2vmin;
  grid-column-end: span 5;
}

@media (max-width: 576px) {
  #about-gif {
    width: 300px;
  }

  #expand-about-btn > h2 {
    font-size: xx-large;
  }
}