#root > :nth-child(n+2):nth-child(-n+3) {
  margin-bottom: 50vh;
}

#anchor {
  position: absolute;
  visibility: hidden;
}

#header {
  width: 100%;
}

.display-1 {
  font-size: 30vw;
  opacity: 0; 
  transform: translateY(50vmin); 
  animation: fade-in 3s forwards;
}

@keyframes fade-in {
  to {
    opacity: 1; 
    transform: translateY(0); 
  }
}


